import React from 'react';
import { useTranslation } from 'react-i18next';
import { Instagram, Facebook, Mail } from 'lucide-react';
import Logo from './Logo';
import SpotifyIcon from './icons/SpotifyIcon';
import YouTubeIcon from './icons/YoutubeIcon';

const Footer = () => {
  const { t } = useTranslation();

  const socialLinks = [
    { icon: <Instagram size={20} />, href: 'https://www.instagram.com/ramonrieramusic/', label: 'Instagram' },
    { icon: <Facebook size={20} />, href: 'https://www.facebook.com/ramonrieramusic/', label: 'Facebook' },
    { icon: <SpotifyIcon size={20} />, href: 'https://open.spotify.com/intl-es/artist/0rU59FhgKyOAIZXq9wX38n?si=K_LQZal6Q4yJpeBL86IyFA', label: 'Spotify' },
    { icon: <YouTubeIcon size={20} />, href: 'https://www.youtube.com/ramonrieramusic', label: 'YouTube' },
    { icon: <Mail size={20} />, href: 'mailto:info@ramonriera.es', label: 'Email' },
  ];

  return (
    <footer className="bg-dark border-t border-gold/20 h-10 flex flex-col justify-between items-center py-8">
      <div className="container mx-auto px-4 flex justify-between items-center h-full">
        {/* Logotipo */}
        <div className="w-40">
          <Logo />
        </div>

        {/* Redes Sociales */}
        <div className="flex space-x-4">
          {socialLinks.map((link) => (
            <a
              key={link.label}
              href={link.href}
              className="text-light hover:text-gold transition-colors"
              aria-label={link.label}
              target="_blank"
              rel="noopener noreferrer"
            >
              {link.icon}
            </a>
          ))}
        </div>
      </div>

      {/* Textos */}
      <div className="text-center text-light/60 text-xs mt-4 pb-4">
        <p className="text-base text-light font-semibold">
          © {new Date().getFullYear()} Ramon Riera
        </p>
        <p className="text-light/60">
          Política de Privacidad - Cookies
        </p>
      </div>
    </footer>
  );
};

export default Footer;
