// import React from 'react';
// import { NavLink } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';
// import { Menu, Globe } from 'lucide-react';
// import Logo from './Logo';

// const Navbar = () => {
//   const { t, i18n } = useTranslation();
//   const [isOpen, setIsOpen] = React.useState(false);

//   const changeLanguage = (lng: string) => {
//     i18n.changeLanguage(lng);
//     setIsOpen(false);
//   };

//   const navLinks = [
//     { to: '/', label: t('nav.home') },
//     { to: '/biography', label: t('nav.biography') },
//     { to: '/events', label: t('nav.events') },
//     { to: '/music', label: t('nav.music') },
//     { to: '/gallery', label: t('nav.gallery') },
//     { to: '/contact', label: t('nav.contact') },
//   ];

//   return (
//     <nav className="bg-dark/95 backdrop-blur-sm fixed w-full z-50 border-b border-gold/20">
//       <div className="container mx-auto px-4">
//         <div className="flex justify-between items-center h-24">
//           <Logo />

//           <div className="hidden md:flex items-center space-x-8">
//             {navLinks.map((link) => (
//               <NavLink
//                 key={link.to}
//                 to={link.to}
//                 className={({ isActive }) =>
//                   `text-light hover:text-gold transition-colors ${
//                     isActive ? 'text-gold' : ''
//                   }`
//                 }
//               >
//                 {link.label}
//               </NavLink>
//             ))}
            
//             <div className="relative group">
//               <button className="text-light hover:text-gold">
//                 <Globe size={20} />
//               </button>
//               <div className="absolute right-0 mt-2 w-24 bg-dark border border-gold/20 rounded-md shadow-lg hidden group-hover:block">
//                 <button
//                   onClick={() => changeLanguage('es')}
//                   className="block w-full text-left px-4 py-2 hover:bg-gold/10"
//                 >
//                   Español
//                 </button>
//                 <button
//                   onClick={() => changeLanguage('en')}
//                   className="block w-full text-left px-4 py-2 hover:bg-gold/10"
//                 >
//                   English
//                 </button>
//                 <button
//                   onClick={() => changeLanguage('de')}
//                   className="block w-full text-left px-4 py-2 hover:bg-gold/10"
//                 >
//                   Deutsch
//                 </button>
//               </div>
//             </div>
//           </div>

//           <button
//             className="md:hidden text-light"
//             onClick={() => setIsOpen(!isOpen)}
//           >
//             <Menu size={24} />
//           </button>
//         </div>
//       </div>

//       {/* Mobile menu */}
//       {isOpen && (
//         <div className="md:hidden bg-dark border-t border-gold/20">
//           {navLinks.map((link) => (
//             <NavLink
//               key={link.to}
//               to={link.to}
//               className={({ isActive }) =>
//                 `block px-4 py-2 text-light hover:bg-gold/10 ${
//                   isActive ? 'text-gold' : ''
//                 }`
//               }
//               onClick={() => setIsOpen(false)}
//             >
//               {link.label}
//             </NavLink>
//           ))}
//           {/* Language selector for mobile */}
//           <div className="border-t border-gold/20 py-2">
//             <div className="px-4 py-2 text-light/60 flex items-center gap-2">
//               <Globe size={16} />
//               <span>Language</span>
//             </div>
//             <button
//               onClick={() => changeLanguage('es')}
//               className="block w-full text-left px-4 py-2 text-light hover:bg-gold/10"
//             >
//               Español
//             </button>
//             <button
//               onClick={() => changeLanguage('en')}
//               className="block w-full text-left px-4 py-2 text-light hover:bg-gold/10"
//             >
//               English
//             </button>
//             <button
//               onClick={() => changeLanguage('de')}
//               className="block w-full text-left px-4 py-2 text-light hover:bg-gold/10"
//             >
//               Deutsch
//             </button>
//           </div>
//         </div>
//       )}
//     </nav>
//   );
// };

// export default Navbar;

import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Menu, Globe } from 'lucide-react';
import Logo from './Logo';

const Navbar = () => {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = React.useState(false);
  const [isLangMenuOpen, setIsLangMenuOpen] = React.useState(false);
  const langMenuRef = React.useRef<HTMLDivElement>(null);

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    setIsOpen(false);
    setIsLangMenuOpen(false);
  };

  // Cerrar menú cuando se hace clic fuera
  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (langMenuRef.current && !langMenuRef.current.contains(event.target as Node)) {
        setIsLangMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const navLinks = [
    { to: '/', label: t('nav.home') },
    { to: '/biography', label: t('nav.biography') },
    { to: '/events', label: t('nav.events') },
    { to: '/music', label: t('nav.music') },
    { to: '/gallery', label: t('nav.gallery') },
    { to: '/contact', label: t('nav.contact') },
  ];

  return (
    <nav className="bg-dark/95 backdrop-blur-sm fixed w-full z-50 border-b border-gold/20">
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center h-24">
          <Logo />

          <div className="hidden md:flex items-center space-x-8">
            {navLinks.map((link) => (
              <NavLink
                key={link.to}
                to={link.to}
                className={({ isActive }) =>
                  `text-light hover:text-gold transition-colors ${
                    isActive ? 'text-gold' : ''
                  }`
                }
              >
                {link.label}
              </NavLink>
            ))}
            
            <div className="relative" ref={langMenuRef}>
              <button 
                className={`text-light hover:text-gold transition-colors p-2 rounded-full ${isLangMenuOpen ? 'bg-gold/10' : ''}`}
                onClick={() => setIsLangMenuOpen(!isLangMenuOpen)}
                aria-label="Selector de idioma"
              >
                <Globe size={20} />
              </button>
              {isLangMenuOpen && (
                <div className="absolute right-0 mt-2 w-36 bg-dark border border-gold/20 rounded-md shadow-lg">
                  <button
                    onClick={() => changeLanguage('es')}
                    className="block w-full text-left px-4 py-2 hover:bg-gold/10 text-light hover:text-gold transition-colors"
                  >
                    Español
                  </button>
                  <button
                    onClick={() => changeLanguage('en')}
                    className="block w-full text-left px-4 py-2 hover:bg-gold/10 text-light hover:text-gold transition-colors"
                  >
                    English
                  </button>
                  <button
                    onClick={() => changeLanguage('de')}
                    className="block w-full text-left px-4 py-2 hover:bg-gold/10 text-light hover:text-gold transition-colors"
                  >
                    Deutsch
                  </button>
                </div>
              )}
            </div>
          </div>

          <button
            className="md:hidden text-light"
            onClick={() => setIsOpen(!isOpen)}
          >
            <Menu size={24} />
          </button>
        </div>
      </div>

      {/* Mobile menu */}
      {isOpen && (
        <div className="md:hidden bg-dark border-t border-gold/20">
          {navLinks.map((link) => (
            <NavLink
              key={link.to}
              to={link.to}
              className={({ isActive }) =>
                `block px-4 py-2 text-light hover:bg-gold/10 ${
                  isActive ? 'text-gold' : ''
                }`
              }
              onClick={() => setIsOpen(false)}
            >
              {link.label}
            </NavLink>
          ))}
          {/* Language selector for mobile */}
          <div className="border-t border-gold/20 py-2">
            <div className="px-4 py-2 text-light/60 flex items-center gap-2">
              <Globe size={16} />
              <span>Language</span>
            </div>
            <button
              onClick={() => changeLanguage('es')}
              className="block w-full text-left px-4 py-2 text-light hover:bg-gold/10"
            >
              Español
            </button>
            <button
              onClick={() => changeLanguage('en')}
              className="block w-full text-left px-4 py-2 text-light hover:bg-gold/10"
            >
              English
            </button>
            <button
              onClick={() => changeLanguage('de')}
              className="block w-full text-left px-4 py-2 text-light hover:bg-gold/10"
            >
              Deutsch
            </button>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;