export default {
  nav: {
    home: 'Startseite',
    biography: 'Biografie',
    events: 'Events',
    music: 'Musik',
    gallery: 'Galerie',
    contact: 'Kontakt'
  },
  
  home: {
    hero: {
      subtitle: 'Saxophonist, DJ & Musikproduzent',
      cta: 'Entdecke meine Dienstleistungen'
    },
    services: {
      title: 'Dienstleistungen',
      saxophone: {
        title: 'Saxophonist',
        description: 'Live-Saxophon-Auftritte für Hochzeiten, Firmenveranstaltungen und exklusive Partys.'
      },
      dj: {
        title: 'DJ',
        description: 'Einzigartige Sets, die elektronische Musik mit Live-Saxophon-Auftritten kombinieren.'
      },
      producer: {
        title: 'Musikproduzent',
        description: 'Produktion elektronischer Musik und Zusammenarbeit mit internationalen Künstlern.'
      }
    },
    about: {
      title: 'Über mich',
      description: 'Mit über einem Jahrzehnt Erfahrung verbinde ich die Eleganz des Saxophons mit der Energie elektronischer Musik.',
      cta: 'Meine Geschichte'
    }
  },

  biography: {
    title: 'Biografie',
    content: 'Ramon Riera ist ein vielseitiger Künstler, der weltweit das Publikum mit seiner einzigartigen Fusion aus Saxophon und elektronischer Musik begeistert. Mit über einem Jahrzehnt Erfahrung in der Musikindustrie hat Ramon einen unverwechselbaren Stil entwickelt, der die Eleganz des klassischen Saxophons mit zeitgenössischen elektronischen Rhythmen verbindet.\n\nAusgebildet am Conservatori del Liceu in Barcelona und nach Perfektionierung seiner Technik an renommierten internationalen Schulen hat Ramon seine Musik zu einigen der exklusivsten Veranstaltungsorte Europas gebracht. Seine Fähigkeit, seine Performance an verschiedene Kontexte anzupassen, von Luxushochzeiten über hochkarätige Firmenveranstaltungen bis hin zu internationalen Clubs, hat ihn zu einem der gefragtesten Künstler im Premium-Eventbereich gemacht.\n\nAls Musikproduzent wurden seine Tracks von einigen der angesehensten DJs der Szene unterstützt, und seine Veröffentlichungen auf namhaften Labels haben die Charts auf Plattformen wie Beatport erreicht. Seine Studioarbeit zeichnet sich durch akribische Detailgenauigkeit und innovative Integration akustischer Instrumente in elektronischen Produktionen aus.\n\nAktuell teilt Ramon seine Zeit zwischen Live-Auftritten, DJ-Sets mit Live-Saxophon und Musikproduktion in seinem persönlichen Studio, wo er weiterhin neue Wege der Genrefusion erkundet und einzigartige musikalische Erlebnisse schafft.'
    },

  events: {
    title: 'Kommende Events',
    noEvents: 'Keine kommenden Events geplant'
  },

  music: {
    title: 'Meine Musik',
    platforms: {
      spotify: 'Auf Spotify hören',
      beatport: 'Auf Beatport kaufen',
      traxsource: 'Auf Traxsource kaufen'
    }
  },

  gallery: {
    title: 'Galerie',
    images: {
      livePerformance: 'Live-Auftritt',
      studioSession: 'Studio-Session',
      djSet: 'DJ-Set'
    }
  },

  galleryVideos: {
    title: 'Videos',
  },

  contact: {
    title: 'Kontakt',
    subtitle: 'Buchen Sie Ihren Termin oder fordern Sie Informationen an',
    form: {
      name: 'Name',
      email: 'E-Mail',
      message: 'Nachricht',
      submit: 'Nachricht senden',
      emailError: 'Bitte geben Sie eine gültige E-Mail-Adresse ein',
      success: 'Nachricht erfolgreich gesendet',
      error: 'Fehler beim Senden der Nachricht. Bitte versuchen Sie es erneut.',
      captchaError: 'Bitte füllen Sie das Captcha aus'
    }
  },

  DJ: {
    title: 'Professioneller DJ', 
    description: 'Einzigartige Sets, die hochwertige elektronische Musik mit Live-Saxophon-Performances vereinen und so ein mitreißendes und anspruchsvolles Musikerlebnis für Ihre Veranstaltung schaffen.', 
    reservation: 'Buchen Sie Ihren Termin',
    styles: {
      titleHouse: 'House, Afro House & Deep House',
      descriptionHouse: 'Elegante und atmosphärische Rhythmen, die für die richtige Stimmung sorgen.',
      titleTech: 'Tech House & Melodic Tech',
      descriptionTech: 'Moderne, mitreißende Klänge, die den Dancefloor in Bewegung halten.',
      titleCommercial: 'kommerziell, Reggaeton & Charts',
      descriptionCommercial: 'Die neuesten Hits gemischt mit Professionalität und Stil.',

    },
    titleEquipment: 'Professionelles Equipment',

    titleEquipmentSound: 'Sound-Equipment',
    equipmentSound1: 'Professioneller Sound-System',
    equipmentSound2: 'Hochwertige Monitore',
    equipmentSound3: 'Pioneer DJ-Mixers', 
    equipmentSound4: 'Professionelle Mikrofone',

    titleEquipmentLighting: 'Lichtgestaltung',
    equipmentLighting1: 'LED-Lichtsystem',
    equipmentLighting2: 'Besondere Effekte',
    equipmentLighting3: 'Nebelmaschine',
    equipmentLighting4: 'Ambientale Beleuchtung',
  },

  Saxophone: {
    title: 'Professioneller Saxophonist',
    description: 'Heben Sie Ihre Veranstaltung auf eine neue Ebene mit Live-Saxophon-Darbietungen, die klassische Eleganz mit moderner Energie verbinden. Ein einzigartiges musikalisches Erlebnis, das Ihre Gäste in seinen Bann ziehen wird. Live-Saxophonauftritte für Hochzeiten, Firmenveranstaltungen und exklusive Partys.',
    reservation: 'Buchen Sie Ihren Termin',
    styles: {
      titleWeddings: 'Luxushochzeiten',
      descriptionWeddings: 'Elegante Darbietungen, die Ihrer Zeremonie und Feier Klasse verleihen.',
      titleCorporate: 'Firmenevents',
      descriptionCorporate: 'Anspruchsvolles Ambiente für Firmenevents, Einweihungen und Galas.',
      titleExclusive: 'Exklusive Partys',
      descriptionExclusive: 'Unwiderstehliche musikalische Erlebnisse für exklusive Feste.',
    },
    titleRepertory: 'Repertorium',

    titleContemporary: 'Zeitgenössische Musik',
    Contemporary1: 'Aktuelle Pop & Charts',
    Contemporary2: 'House & Deep House',
    Contemporary3: 'Lounge & Chill-Out',
    Contemporary4: 'Zeitgenössischer Jazz',

    titleClassical: 'Atemporale Klassiker',
    Classical1: 'Jazz-Standards',
    Classical2: 'Bossa-Nova',
    Classical3: 'Klassische Balladen',
    Classical4: 'Salsa & Latin Musik',
  },

  Producer: { 
    title: 'Musikproduzent',
    description: 'Musikproduktion, spezialisiert auf elektronische Musik mit einem einzigartigen Ansatz. Schafft Musik mit modernster Technologie und arbeitet mit internationalen Künstlern zusammen. Umfassende Erfahrung in der Musikproduktion für Veranstaltungen, Werbung und Audioprojekte.',
    reservation: 'Kontaktieren Sie mich',
    styles: {
      titleProductions: 'Musikproduktion',
      descriptionProductions: 'Kreation und Produktion von elektronischer und akustischer Musik in verschiedenen Stilrichtungen.',
      titleMix: 'Abmischung & Mastering',
      descriptionMix: 'Professionelles Mixing und Mastering, damit Ihre Musik optimal klingt.',
      titleColaborations: 'Kolaborationen',
      descriptionColaborations: 'Kolaborationsprojekte mit internationalen Künstlern und Labels.',
    },
    titleStudio: 'Professionelles Studio',
    equipment: 'Professionelles Equipment',
    equipment1: 'Professionelles DAW (Logic Pro & Ableton Live)',
    equipment2: 'Professionelles Mikrofon (Neumann)',
    equipment3: 'Hochwertige Monitore (Genelec)',
    equipment4: 'Professioneller Meister (Izotope)',

    titleGenres: 'Genre',
    genres1: 'House & Deep House',
    genres2: 'Tech House & Melodic Tech',
    genres3: 'Lo-Fi & Hip Hop',
    genres4: 'Ambience & Chill Out',

  }
};