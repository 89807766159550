import React from "react";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

const Biography = () => {
  const { t } = useTranslation();

  return (
    <div className="pt-24">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="max-w-4xl mx-auto px-4"
      >
        <h1 className="page-title text-center">{t("biography.title")}</h1>
        <div className="grid md:grid-cols-2 gap-8 items-start">
          <div>
            <img
              src="/images/rr117.jpg"
              alt="Ramon Riera"
              className="rounded-lg shadow-xl w-full"
            />
          </div>
          <div className="space-y-6 text-light/80">
            {t("biography.content").split("\n\n").map((paragraph, index) => (
              <p key={index}>{paragraph}</p>
            ))}
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default Biography;
