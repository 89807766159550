import React from "react";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { Music2, Wand2, Radio } from "lucide-react";

const Producer = () => {
  const { t } = useTranslation();

  const services = [
    {
      icon: <Music2 className="w-6 h-6" />,
      title: t("Producer.styles.titleProductions"),
      description: t("Producer.styles.descriptionProductions"),
    },
    {
      icon: <Wand2 className="w-6 h-6" />,
      title: t("Producer.styles.titleMix"),
      description: t("Producer.styles.descriptionMix"),
    },
    {
      icon: <Radio className="w-6 h-6" />,
      title: t("Producer.styles.titleColaborations"),
      description: t("Producer.styles.descriptionColaborations"),
    },
  ];

  return (
    <div className="pt-24">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="max-w-6xl mx-auto px-4"
      >
        <div className="grid md:grid-cols-2 gap-12 items-center mb-16">
          <div>
            <h1 className="text-4xl md:text-5xl font-display text-gold mb-6">
              {t("Producer.title")}
            </h1>
            <p className="text-light/80 text-lg leading-relaxed mb-8">
              {t("Producer.description")}
            </p>
            <a
              href="/contact"
              className="inline-block px-8 py-3 bg-gold text-dark font-semibold rounded-full hover:bg-gold/90 transition-colors"
            >
              {t("Producer.reservation")}
            </a>
          </div>
          <div>
            <img
              src="/images/rrEstudio01.jpg"
              alt="Estudio de producción"
              className="rounded-lg shadow-xl"
            />
          </div>
        </div>

        <div className="grid md:grid-cols-3 gap-8 mb-16">
          {services.map((service, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="bg-light/5 p-6 rounded-lg border border-gold/20"
            >
              <div className="text-gold mb-4">{service.icon}</div>
              <h3 className="text-xl font-display text-gold mb-2">
                {service.title}
              </h3>
              <p className="text-light/80">{service.description}</p>
            </motion.div>
          ))}
        </div>

        <div className="bg-light/5 rounded-lg p-8 border border-gold/20">
          <h2 className="text-3xl font-display text-gold mb-6">
            {t("Producer.titleStudio")}
          </h2>
          <div className="grid md:grid-cols-2 gap-8">
            <div>
              <h3 className="text-xl font-display text-gold mb-4">
                {t("Producer.equipment")}
              </h3>
              <ul className="space-y-2 text-light/80">
                <li>• {t("Producer.equipment1")}</li>
                <li>• {t("Producer.equipment2")}</li>
                <li>• {t("Producer.equipment3")}</li>
                <li>• {t("Producer.equipment4")}</li>
              </ul>
            </div>
            <div>
              <h3 className="text-xl font-display text-gold mb-4">
                {t("Producer.titleGenres")}
              </h3>
              <ul className="space-y-2 text-light/80">
                <li>• {t("Producer.genres1")}</li>
                <li>• {t("Producer.genres2")}</li>
                <li>• {t("Producer.genres3")}</li>
                <li>• {t("Producer.genres4")}</li>
                <li>• {t("Producer.genres5")}</li>
              </ul>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default Producer;
