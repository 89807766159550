import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { Calendar } from 'lucide-react';

const Events = () => {
  const { t } = useTranslation();

  const events = [
    {
      date: '2024-10-15',
      venue: 'Nikki Beach Mallorca',
      location: 'Mallorca, Spain',
      type: 'Live Saxophone'
    },
    {
      date: '2024-12-22',
      venue: 'Luxury Wedding Venue',
      location: 'Private Location, Spain',
      type: 'DJ Set & Live Saxophone'
    },
    {
      date: '2024-12-23',
      venue: 'Luxury Wedding Venue',
      location: 'Private Location, Spain',
      type: 'DJ Set & Live Saxophone'
    },
    {
      date: '2025-06-15',
      venue: 'Nikki Beach Mallorca',
      location: 'Mallorca, Spain',
      type: 'Live Saxophone'
    }
  ];

  return (
    <div className="pt-24">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="max-w-4xl mx-auto"
      >
        <h1 className="page-title text-center">{t('events.title')}</h1>
        <div className="space-y-6">
          {events.length > 0 ? (
            events.map((event, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="bg-light/5 rounded-lg p-6 border border-gold/20"
              >
                <div className="flex items-start gap-4">
                  <Calendar className="text-gold" />
                  <div>
                    <h3 className="text-xl font-display text-gold">{event.venue}</h3>
                    <p className="text-light/80">{event.location}</p>
                    <p className="text-light/60 text-sm mt-2">{event.type}</p>
                    <p className="text-gold/80 text-sm mt-1">
                      {new Date(event.date).toLocaleDateString()}
                    </p>
                  </div>
                </div>
              </motion.div>
            ))
          ) : (
            <p className="text-center text-light/60">{t('events.noEvents')}</p>
          )}
        </div>
      </motion.div>
    </div>
  );
};

export default Events;