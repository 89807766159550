import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Navbar from './Navbar';
import Footer from './Footer';
import { motion } from 'framer-motion';

const Layout = () => {
  const location = useLocation();  // Obtener la ubicación actual de la ruta

  // Este efecto se ejecuta cada vez que cambia la ubicación (cuando navegas a una nueva página)
  useEffect(() => {
    // Asegura que el scroll siempre suba al principio
    window.scrollTo(0, 0);
    
    // También asegurar el comportamiento en caso de recarga de página
    const handleBeforeUnload = () => {
      window.scrollTo(0, 0); // Volver al inicio al recargar
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    // Limpiar el listener cuando el componente se desmonte
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [location]);  // Se ejecuta cada vez que cambie la ubicación

  return (
    <div className="min-h-screen bg-dark text-light flex flex-col">
      <Navbar />
      <motion.main
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="flex-grow container mx-auto px-4 py-8"
      >
        <Outlet />
      </motion.main>
      <Footer />
    </div>
  );
};

export default Layout;
