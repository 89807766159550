export default {
  nav: {
    home: 'Home',
    biography: 'Biography',
    events: 'Events',
    music: 'Music',
    gallery: 'Gallery',
    contact: 'Contact'
  },
  
  home: {
    hero: {
      subtitle: 'Saxophonist, DJ & Music Producer',
      cta: 'Discover my services'
    },
    services: {
      title: 'Services',
      saxophone: {
        title: 'Saxophonist',
        description: 'Live saxophone performances for weddings, corporate events, and exclusive parties.'
      },
      dj: {
        title: 'DJ',
        description: 'Unique sets combining electronic music with live saxophone performances.'
      },
      producer: {
        title: 'Music Producer',
        description: 'Electronic music production and collaborations with international artists.'
      }
    },
    about: {
      title: 'About me',
      description: 'With over a decade of experience, I blend the elegance of saxophone with the energy of electronic music to create unique experiences.',
      cta: 'Learn my story'
    }
  },

  biography: {
    title: 'Biography',
    content: 'Ramon Riera is a versatile artist who has captivated audiences worldwide with his unique fusion of saxophone and electronic music. With over a decade of experience in the music industry, Ramon has developed a distinctive style that combines the elegance of classical saxophone with contemporary electronic rhythms.\n\nTrained at the Conservatori del Liceu in Barcelona and having perfected his technique at prestigious international schools, Ramon has brought his music to some of Europe\'s most exclusive venues. His ability to adapt his performance to different contexts, from luxury weddings to high-end corporate events and international clubs, has made him one of the most sought-after artists in the premium events sector.\n\nAs a music producer, his tracks have been supported by some of the most respected DJs in the scene, and his releases on prominent labels have reached the charts on platforms like Beatport. His studio work is characterized by meticulous attention to detail and innovative integration of acoustic instruments in electronic productions.\n\nCurrently, Ramon divides his time between live performances, DJ sets with live saxophone, and music production in his personal studio, where he continues to explore new ways of fusing genres and creating unique musical experiences.'
  },

  events: {
    title: 'Upcoming Events',
    noEvents: 'No upcoming events scheduled'
  },

  music: {
    title: 'My Music',
    platforms: {
      spotify: 'Listen on Spotify',
      beatport: 'Buy on Beatport',
      traxsource: 'Buy on Traxsource'
    }
  },

  gallery: {
    title: 'Gallery',
    images: {
      livePerformance: 'Live Performance',
      studioSession: 'Studio Session',
      djSet: 'DJ Set'
    }
  },

  galleryVideos: {
    title: 'Videos',
  },

  contact: {
    title: 'Contact',
    subtitle: 'Book your date or request information',
    form: {
      name: 'Name',
      email: 'Email',
      message: 'Message',
      submit: 'Send message',
      emailError: 'Please enter a valid email address',
      success: 'Message sent successfully',
      error: 'Error sending message. Please try again.',
      captchaError: 'Please complete the captcha'
    }
  },

  DJ: {
    title: 'Professional DJ', 
    description: 'Unique sets that fuse quality electronic music with live saxophone performances, creating an immersive and sophisticated musical experience for your event.', 
    reservation: 'Book your date',
    styles: {
      titleHouse: 'House, Afro House & Deep House',
      descriptionHouse: 'Elegant and atmospheric rhythms perfect for creating the right mood.',
      titleTech: 'Tech House & Melodic Tech',
      descriptionTech: 'Modern, immersive sounds that keep the dancefloor moving.',
      titleCommercial: 'Commercial, Reggaeton & Charts',
      descriptionCommercial: 'The latest hits mixed with professionalism and style.',

    },
    titleEquipment: 'Professional equipment',

    titleEquipmentSound: 'Sound equipment',
    equipmentSound1: 'Professional sound system',
    equipmentSound2: 'High quality monitors',
    equipmentSound3: 'Pioneer DJ mixers', 
    equipmentSound4: 'Professional microphones',

    titleEquipmentLighting: 'Lighting',
    equipmentLighting1: 'LED lighting system',
    equipmentLighting2: 'Special effects',
    equipmentLighting3: 'Fog machine',
    equipmentLighting4: 'Ambient lighting',
  },

  Saxophone: {
    title: 'Professional saxophonist',
    description: 'Elevate your event to another level with live saxophone performances that combine classical elegance with contemporary energy. A unique musical experience that will captivate your guests. Live saxophone performances for weddings, corporate events and exclusive parties.',
    reservation: 'Book your date',
    styles: {
      titleWeddings: 'Luxury weddings',
      descriptionWeddings: 'Elegant performances that bring distinction and class to your ceremony and celebration.',
      titleCorporate: 'Corporate events',
      descriptionCorporate: 'Sophisticated ambience for corporate events, inaugurations and galas.',
      titleExclusive: 'Exclusive parties',
      descriptionExclusive: 'Irresistible musical experiences for exclusive celebrations.',
    },

    titleRepertory: 'Repertory',

    titleContemporary: 'Contemporary Music',
    Contemporary1: 'Current Pop & Charts',
    Contemporary2: 'House & Deep House',
    Contemporary3: 'Lounge & Chill Out',
    Contemporary4: 'Contemporary Jazz',

    titleClassical: 'Atemporal Classics',
    Classical1: 'Jazz Standards',
    Classical2: 'Bossa Nova',
    Classical3: 'Classic ballads',
    Classical4: 'Salsa & Latin Music',
  },

  Producer: { 
    title: 'Music Producer',
    description: 'Music production specialising in electronic music with a unique approach. Creates music with state of the art technology and collaborations with international artists. Extensive experience in music production for events, advertising and audio projects.',
    reservation: 'Contact me',
    styles: {
      titleProductions: 'Music Production',
      descriptionProductions: 'Creation and production of electronic and acoustic music in multiple styles.',
      titleMix: 'Mixing & Mastering',
      descriptionMix: 'Professional mixing and mastering for your music to sound its best.',
      titleColaborations: 'Colaborations',
      descriptionColaborations: 'Collaboration projects with international artists and labels.',
    },
    titleStudio: 'Studio Professional',
    equipment: 'Professional equipment',
    equipment1: 'Professional DAW (Logic Pro & Ableton Live)',
    equipment2: 'Professional microphone (Neumann)',
    equipment3: 'High quality monitors (Genelec)',
    equipment4: 'Professional Master (Izotope)',

    titleGenres: 'Genres',
    genres1: 'House & Deep House',
    genres2: 'Tech House & Melodic Tech',
    genres3: 'Lo-Fi & Hip Hop',
    genres4: 'Ambience & Chill Out',

  }

};