import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { Music2, Disc3, Target } from 'lucide-react';
import SpotifyIcon from '../components/icons/SpotifyIcon';
import BeatportIcon from '../components/icons/BeatportIcon';
import TraxsourceIcon from '../components/icons/TraxsourceIcon';

const Music = () => {
  const { t } = useTranslation();

  const platforms = [
    {
      name: 'Spotify',
      icon: <SpotifyIcon className="w-6 h-6" />,
      url: 'https://open.spotify.com/intl-es/artist/0rU59FhgKyOAIZXq9wX38n?si=K_LQZal6Q4yJpeBL86IyFA',
      label: t('music.platforms.spotify')
      
    },
    {
      name: 'Beatport',
      icon: <BeatportIcon className="w-6 h-6" />,
      url: 'https://www.beatport.com/es/artist/ramon-riera/115605?srsltid=AfmBOoqknJkGlCyT3jVvdUFBKpDTT2lhc-XsjciXHxxgYY14d2gfgrMi',
      label: t('music.platforms.beatport')
    },
    {
      name: 'Traxsource',
      icon: <TraxsourceIcon className="w-6 h-6" />,
      url: 'https://www.traxsource.com/artist/75833/ramon-riera',
      label: t('music.platforms.traxsource')
    }
  ];

  return (
    <div className="pt-24">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="max-w-4xl mx-auto"
      >
        <h1 className="page-title text-center">{t('music.title')}</h1>
        
        <div className="grid md:grid-cols-3 gap-8 mt-12">
          {platforms.map((platform) => (
            <motion.a
              key={platform.name}
              href={platform.url}
              whileHover={{ scale: 1.05 }}
              className="bg-light/5 rounded-lg p-6 border border-gold/20 hover:border-gold/40 transition-colors group"
              target='_blank'
              rel="noopener noreferrer"
            >
              <div className="flex flex-col items-center text-center space-y-4">
                <div className="text-gold group-hover:text-gold/80 transition-colors">
                  {platform.icon}
                </div>
                <h3 className="text-xl font-display text-gold">{platform.name}</h3>
                <p className="text-light/80">{platform.label}</p>
              </div>
            </motion.a>
          ))}
        </div>

        <div className="mt-16">
          <iframe
            src="https://open.spotify.com/embed/artist/0rU59FhgKyOAIZXq9wX38n"
            width="100%"
            height="380"
            frameBorder="0"
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            className="rounded-lg"
          ></iframe>
        </div>
      </motion.div>
    </div>
  );
};

export default Music;