export default {
  nav: {
    home: 'Inicio',
    biography: 'Biografía',
    events: 'Eventos',
    music: 'Música',
    gallery: 'Galería',
    contact: 'Contacto'
  },
  
  home: {
    hero: {
      subtitle: 'Saxofonista, DJ & Productor Musical',
      cta: 'Descubre mis servicios'
    },
    services: {
      title: 'Servicios',
      saxophone: {
        title: 'Saxofonista',
        description: 'Actuaciones en directo con saxofón para bodas, eventos corporativos y fiestas exclusivas.'
      },
      dj: {
        title: 'DJ',
        description: 'Sets únicos que combinan música electrónica con actuaciones en directo de saxofón.'
      },
      producer: {
        title: 'Productor Musical',
        description: 'Producción de música electrónica y colaboraciones con artistas internacionales.'
      }
    },
    about: {
      title: 'Sobre mí',
      description: 'Con más de una década de experiencia, fusiono la elegancia del saxofón con la energía de la música electrónica para crear experiencias únicas.',
      cta: 'Conoce mi historia'
    }
  },

  biography: {
    title: 'Biografía',
    content: 'Ramon Riera es un artista versátil que ha cautivado a audiencias en todo el mundo con su única fusión de saxofón y música electrónica. Con más de una década de experiencia en la industria musical, Ramon ha desarrollado un estilo distintivo que combina la elegancia del saxofón clásico con ritmos electrónicos contemporáneos.\n\nFormado en el Conservatori del Liceu de Barcelona y habiendo perfeccionado su técnica en prestigiosas escuelas internacionales, Ramon ha llevado su música a algunos de los lugares más exclusivos de Europa. Su capacidad para adaptar su actuación a diferentes contextos, desde bodas de lujo hasta eventos corporativos de alto nivel y clubes internacionales, lo ha convertido en uno de los artistas más solicitados en el sector de eventos premium.\n\nComo productor musical, sus tracks han sido apoyados por algunos de los DJs más respetados de la escena, y sus lanzamientos en sellos destacados han alcanzado las listas de éxitos en plataformas como Beatport. Su trabajo en el estudio se caracteriza por una meticulosa atención al detalle y una innovadora integración de instrumentos acústicos en producciones electrónicas.\n\nActualmente, Ramon divide su tiempo entre actuaciones en directo, sesiones de DJ con saxofón en vivo y producción musical en su estudio personal, donde continúa explorando nuevas formas de fusionar géneros y crear experiencias musicales únicas.'
  },

  events: {
    title: 'Próximos Eventos',
    noEvents: 'No hay eventos programados próximamente'
  },

  music: {
    title: 'Mi Música',
    platforms: {
      spotify: 'Escucha en Spotify',
      beatport: 'Compra en Beatport',
      traxsource: 'Compra en Traxsource'
    }
  },

  gallery: {
    title: 'Galería',
    images: {
      livePerformance: 'Actuación en Directo',
      studioSession: 'Sesión de Estudio',
      djSet: 'Sesión DJ'
    }
  },

  galleryVideos: {
    title: 'Vídeos',
  },

  contact: {
    title: 'Contacto',
    subtitle: 'Reserva tu fecha o solicita información',
    form: {
      name: 'Nombre',
      email: 'Email',
      message: 'Mensaje',
      submit: 'Enviar mensaje',
      emailError: 'Por favor, introduce un email válido',
      success: 'Mensaje enviado correctamente',
      error: 'Error al enviar el mensaje. Por favor, inténtalo de nuevo.',
      captchaError: 'Por favor, completa el captcha'
    }
  },

  DJ: {
    title: 'DJ Profesional', 
    description: 'Sets únicos que fusionan música electrónica de calidad con actuaciones en directo de saxofón, creando una experiencia musical inmersiva y sofisticada para tu evento.', 
    reservation: 'Reserva tu fecha',
    styles: {
      titleHouse: 'House, Afro House & Deep House',
      descriptionHouse: 'Ritmos elegantes y atmosféricos perfectos para crear el ambiente adecuado.',
      titleTech: 'Tech House & Melodic Tech',
      descriptionTech: 'Sonidos modernos y envolventes que mantienen la pista de baile activa.',
      titleCommercial: 'Comercial, Reggaeton & Charts',
      descriptionCommercial: 'Los éxitos más actuales mezclados con profesionalidad y estilo.',
      
    },
    titleEquipment: 'Equipamiento profesional',

    titleEquipmentSound: 'Equipo de sonido',
    equipmentSound1: 'Sistema de sonido profesional',
    equipmentSound2: 'Monitores de alta calidad',
    equipmentSound3: 'Mezcladores Pioneer DJ', 
    equipmentSound4: 'Microfonía profesional',

    titleEquipmentLighting: 'Iluminación',
    equipmentLighting1: 'Sistema de iluminación LED',
    equipmentLighting2: 'Efectos especiales',
    equipmentLighting3: 'Maquina de humo',
    equipmentLighting4: 'Iluminación ambiental',
  },

  Saxophone: {
    title: 'Saxofonista Profesional',
    description: 'Eleva tu evento a otro nivel con actuaciones en directo de saxofón que combinan elegancia clásica con energía contemporánea. Una experiencia musical única que cautivará a tus invitados. Actuaciones en directo con saxofón para bodas, eventos corporativos y fiestas exclusivas.',
    reservation: 'Reserva tu fecha',
    styles: {
      titleWeddings: 'Bodas de lujo',
      descriptionWeddings: 'Actuaciones elegantes que aportan distinción y clase a tu ceremonia y celebración.',
      titleCorporate: 'Eventos corporativos',
      descriptionCorporate: 'Ambiente sofisticado para eventos empresariales, inauguraciones y galas.',
      titleExclusive: 'Fiestas privadas',
      descriptionExclusive: 'Experiencias musicales irresistibles para celebraciones exclusivas.',
    },
    titleRepertory: 'Repertorio',

    titleContemporary: 'Música Contemporánea',
    Contemporary1: 'Pop & Charts actuales',
    Contemporary2: 'House & Deep House',
    Contemporary3: 'Lounge & Chill Out',
    Contemporary4: 'Jazz Contemporáneo',

    titleClassical: 'Clásicos Atemporales',
    Classical1: 'Jazz Standards',
    Classical2: 'Bossa Nova',
    Classical3: 'Baladas Clásicas',
    Classical4: 'Salsa & Música Latina',
  },

  Producer: { 
    title: 'Productor Musical',
    description: 'Producción musical especializada en música electrónica con un enfoque único. Crea musica con tecnología de punta y colaboraciones con artistas internacionales. Amplia experiencia en producción de música para eventos, publicidad y proyectos de audio.',
    reservation: 'Contactar',
    styles: {
      titleProductions: 'Producción Musical',
      descriptionProductions: 'Creación y producción de música electrónica y acústica de múltiples estilos.',
      titleMix: 'Mezcla y Masterización',
      descriptionMix: 'Mezcla y masterización profesionales para que tu música suene lo mejor posible.',
      titleColaborations: 'Colaboraciones',
      descriptionColaborations: 'Proyectos colaborativos con artistas y sellos internacionales.',
    },
    titleStudio: 'Estudio Profesional',
    equipment: 'Equipamiento profesional',
    equipment1: 'DAW profesional (Logic Pro & Ableton Live)',
    equipment2: 'Microfonía profesional (Neumann)',
    equipment3: 'Monitores de alta calidad (Genelec)',
    equipment4: 'Máster profesional (Izotope)',

    titleGenres: 'Géneros',
    genres1: 'House & Deep House',
    genres2: 'Tech House & Melodic Tech',
    genres3: 'Lo-Fi & Hip Hop',
    genres4: 'Ambience & Chill Out',

  }
};