import React from "react";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { Disc3, Music4, Waves } from "lucide-react";


const DJ = () => {
  const { t } = useTranslation();

  const styles = [
    {
      icon: <Waves className="w-6 h-6" />,
      title: t("DJ.styles.titleHouse"),
      description: t("DJ.styles.descriptionHouse"),    
    },
    {
      icon: <Music4 className="w-6 h-6" />,
      title: t("DJ.styles.titleTech"),
      description: t("DJ.styles.descriptionTech"),
    },
    {
      icon: <Disc3 className="w-6 h-6" />,
      title: t("DJ.styles.titleCommercial"),
      description: t("DJ.styles.descriptionCommercial"),
    },
  ];

  return (
    <div className="pt-24">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="max-w-6xl mx-auto px-4"
      >
        <div className="grid md:grid-cols-2 gap-12 items-center mb-16">
          <div>
            <h1 className="text-4xl md:text-5xl font-display text-gold mb-6">
              {t("DJ.title")}
            </h1>
            <p className="text-light/80 text-lg leading-relaxed mb-8">
              {t("DJ.description")}{" "}
            </p>
            <a
              href="/contact"
              className="inline-block px-8 py-3 bg-gold text-dark font-semibold rounded-full hover:bg-gold/90 transition-colors"
            >
              {t("DJ.reservation")}
            </a>
          </div>
          <div>
            <img
              src="/images/rrdj01.jpeg"
              alt="DJ Set"
              className="rounded-lg shadow-xl"
            />
          </div>
        </div>

        <div className="grid md:grid-cols-3 gap-8 mb-16">
          {styles.map((style, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="bg-light/5 p-6 rounded-lg border border-gold/20"
            >
              <div className="text-gold mb-4">{style.icon}</div>
              <h3 className="text-xl font-display text-gold mb-2">
                {style.title}
              </h3>
              <p className="text-light/80">{style.description}</p>
            </motion.div>
          ))}
        </div>

        <div className="bg-light/5 rounded-lg p-8 border border-gold/20">
          <h2 className="text-3xl font-display text-gold mb-6">
          {t('DJ.titleEquipment')}
          </h2>
          <div className="grid md:grid-cols-2 gap-8">
            <div>
              <h3 className="text-xl font-display text-gold mb-4">
                {t('DJ.titleEquipmentSound')}
              </h3>
              <ul className="space-y-2 text-light/80">
                <li>• {t('DJ.equipmentSound1')}</li>
                <li>• {t('DJ.equipmentSound2')}</li>
                <li>• {t('DJ.equipmentSound3')}</li>
                <li>• {t('DJ.equipmentSound4')}</li>
              </ul>
            </div>
            <div>
              <h3 className="text-xl font-display text-gold mb-4">
              {t('DJ.titleEquipmentLighting')}
              </h3>
              <ul className="space-y-2 text-light/80">
                <li>• {t('DJ.equipmentLighting1')}</li>
                <li>• {t('DJ.equipmentLighting2')}</li>
                <li>• {t('DJ.equipmentLighting3')}</li>
                <li>• {t('DJ.equipmentLighting4')}</li>
              </ul>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default DJ;
