import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

const Gallery = () => {
  const { t } = useTranslation();

  // Estructura de grupos de fotos
  const imageGroups = [
    {
      groupName: t('gallery.images.livePerformance'), // Traducción
      images: [
        { url: '/images/rr117.jpg', caption: 'Actuación 1' },
        { url: '/images/rr78.jpg', caption: 'Actuación 2' },
        { url: '/images/rr119.jpg', caption: 'Actuación 3' }
      ]
    },
    {
      groupName: t('gallery.images.studioSession'), // Traducción
      images: [
        { url: '/images/rr309.jpg', caption: 'Estudio 1' },
        { url: '/images/rr310.jpg', caption: 'Estudio 2' }
      ]
    },
    {
      groupName: t('gallery.images.djSet'), // Traducción
      images: [
        { url: '/images/rrdj01.jpeg', caption: 'DJ Set 1' },
        { url: '/images/rrdj02.jpeg', caption: 'DJ Set 2' }
      ]
    }
  ];

  // Estructura de vídeos
  const videoUrls = [
    'https://www.youtube.com/embed/VJRAky4rh8A',
    'https://www.youtube.com/embed/9WuZ_hXVlBw'
  
  ];

  // Estado para controlar el grupo y el elemento seleccionado
  const [selectedGroupIndex, setSelectedGroupIndex] = useState<number | null>(null);
  const [selectedItemIndex, setSelectedItemIndex] = useState<number>(0);
  const [isVideoSelected, setIsVideoSelected] = useState<boolean>(false); // Para diferenciar si estamos viendo videos o imágenes

  const handleGroupClick = (index: number, isVideo: boolean = false) => {
    setSelectedGroupIndex(index); // Cambiar al grupo de fotos o vídeos seleccionado
    setSelectedItemIndex(0); // Iniciar en el primer item del grupo
    setIsVideoSelected(isVideo); // Cambiar el estado para saber si se está viendo video
  };

  const handleCloseGallery = () => {
    setSelectedGroupIndex(null); // Cerrar la galería
    setIsVideoSelected(false); // Resetear si está viendo vídeos
  };

  // Lógica para cambiar de imagen o vídeo
  const changeItem = (direction: 'left' | 'right') => {
    if (isVideoSelected) {
      setSelectedItemIndex((prevIndex) =>
        direction === 'left'
          ? (prevIndex === 0 ? videoGroups[selectedGroupIndex!].videos.length - 1 : prevIndex - 1)
          : (prevIndex === videoGroups[selectedGroupIndex!].videos.length - 1 ? 0 : prevIndex + 1)
      );
    } else {
      setSelectedItemIndex((prevIndex) =>
        direction === 'left'
          ? (prevIndex === 0 ? imageGroups[selectedGroupIndex!].images.length - 1 : prevIndex - 1)
          : (prevIndex === imageGroups[selectedGroupIndex!].images.length - 1 ? 0 : prevIndex + 1)
      );
    }
  };

  return (
    <div className="pt-24">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h1 className="page-title text-center">{t('gallery.title')}</h1>

        {/* Galería de imágenes */}
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
          {imageGroups.map((group, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="group relative aspect-square overflow-hidden rounded-lg cursor-pointer"
            >
              <button
                onClick={() => handleGroupClick(index, false)}  // Indicar que no es un video
                className="w-full h-full relative focus:outline-none"
              >
                <div className="w-full h-full relative group">
                  <img
                    src={group.images[0].url}
                    alt={group.groupName}
                    className="object-cover w-full h-full transform group-hover:scale-110 transition-transform duration-500"
                  />
                  <div className="absolute inset-0 bg-dark/60 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-center justify-center">
                    <p className="text-light font-display text-xl">{group.groupName}</p>
                  </div>
                </div>
              </button>
            </motion.div>
          ))}
        </div>

        {/* Título de Vídeos */}
        <h2 className="page-title text-center mt-12">{t('galleryVideos.title')}</h2>

        {/* Galería de vídeos */}
        <div className="grid md:grid-cols-2 gap-6 mt-6">
          {videoUrls.map((url, index) => (
            <div key={index} className="w-full h-80">
              <iframe
                width="100%"
                height="100%"
                src={url}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="object-cover w-full h-full rounded-lg"
              ></iframe>
            </div>
          ))}
        </div>

        {/* Si hay un grupo seleccionado, mostrar el "scroll" de imágenes o vídeos */}
        {selectedGroupIndex !== null && (
          <div className="fixed inset-0 bg-dark/80 flex items-center justify-center z-50">
            <div className="relative w-full md:w-3/4 lg:w-2/3 xl:w-2/3">
              {/* Botón para cerrar la galería */}
              <button
                onClick={handleCloseGallery}
                className="absolute top-4 right-4 text-white text-3xl p-2 bg-gold rounded-full hover:bg-dark/80 transition-all z-50"
              >
                X
              </button>

              {/* Flechas para navegar entre vídeos o imágenes */}
              {!isVideoSelected && (
                <>
                  <button
                    onClick={() => changeItem('left')}
                    className="absolute left-4 top-1/2 transform -translate-y-1/2 text-white text-4xl p-4 bg-gold rounded-full hover:bg-dark/80 transition-all z-50"
                  >
                    ←
                  </button>
                  <button
                    onClick={() => changeItem('right')}
                    className="absolute right-4 top-1/2 transform -translate-y-1/2 text-white text-4xl p-4 bg-gold rounded-full hover:bg-dark/80 transition-all z-50"
                  >
                    →
                  </button>
                </>
              )}

              {/* Contenedor de item seleccionado (Imagen o vídeo) */}
              <div className="flex justify-center items-center w-full h-[600px]">
                {/* Si es un vídeo */}
                {isVideoSelected ? (
                  <div className="w-full h-full rounded-lg overflow-hidden">
                    <iframe
                      width="100%"
                      height="100%"
                      src={videoGroups[selectedGroupIndex!].videos[selectedItemIndex].url}
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      className="object-cover w-full h-full rounded-lg"
                    ></iframe>
                  </div>
                ) : (
                  // Si es una imagen
                  <div className="w-full h-full rounded-lg overflow-hidden">
                    <img
                      src={imageGroups[selectedGroupIndex!].images[selectedItemIndex].url}
                      alt={imageGroups[selectedGroupIndex!].images[selectedItemIndex].caption}
                      className="object-cover w-full h-full rounded-lg"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </motion.div>
    </div>
  );
};

export default Gallery;
